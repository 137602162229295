<template>
  <div class="d-flex justify-center py-10">

    <template v-if="joinError">
      <div class="red--text my-4">
        {{ joinError }}
      </div>
    </template>
    <template v-else>
      <div v-if="providerIdFromStorage">
        您已經是{{ this.providerObj.provider_name }}成員
      </div>
      <div v-else>
        <div v-if="hasJoined">
          <div v-if="joinSuccessfully && provider">
            您已成功加入{{ provider.name }}
          </div>
        </div>
      </div>
    </template>

  </div>
</template>
<script>
export default {
  mounted() {
    // if(!this.providerIdFromStorage) {
    //   this.join()
    // }
    this.join() //暫時移除判斷
  },
  data: () => ({
    hasJoined: false,
    joinSuccessfully: false,
    provider: null,
    joinError: null,
  }),
  methods: {
    async join() {
      this.join = false
      try {
        const params = {
          code: this.code,
        }
        const { token, provider } = await this.$api.collection.providerApi.join(this.providerId, params)
        this.joinSuccessfully = true
        this.provider = provider
        this.$tokenStore.set(token)
        await this.readMemberProfile()
        this.$snotify.success(null, `您已成功加入: ${provider.name}`)
      } catch (error) {

        if (error.data) {
          let { code, message } = error.data
          if (code) {
            code = parseInt(code)
            switch (code) {
              case 1: {
                this.joinError = `您已是 ${this.providerObj.provider_name} 的成員`
                this.$snotify.error(this.joinError, '加入失敗')
                console.log(message);
                break
              }
              case 2: {
                this.joinError = `此連結已失效，請再次邀請`
                this.$snotify.error(this.joinError, '加入失敗')
                console.log(message);
                break
              }
              default:
                this.joinError = 'cannot understand the code'
                this.$snotify.error('cannot understand the code', '加入失敗')
                console.log(error);
            }
          } else {
            this.joinError = 'cannot find the code'
            this.$snotify.error('cannot find the code', '加入失敗')
            console.log(error);
          }
        } else {
          this.joinError = 'data 內無可用參數'
          this.$snotify.error('data 內無可用參數', '加入失敗')
          console.log(error);
        }

        console.warn(error)

      } finally {
        this.hasJoined = true
      }
    },
    async readMemberProfile() {
      try {
        const data = await this.$api.collection.memberApi.profile()
        this.$store.dispatch(`member/set`, data)
      } catch (error) {
        console.warn(error)
      }
    },
  },
  computed: {
    // otp驗證碼
    code() {
      return this.$route.query.code
    },
    providerIdFromStorage() {
      return this.$store.getters['member/providerId'];
    },
    providerId() {
      return this.$route.params.provider_id;
    },
    providerObj() {
      const vm = this
      return this.$store.getters['member/memberProviders'].filter((item) => {
        return item.provider_id == vm.providerId
      })[0]
    },
  },
};
</script>
